.taskInfoBox {
  display: flex;
  flex-direction: column;
}

.taskStatus {
  font-weight: 600;

  &.canceled {
    color: var(--red500);
  }

  &.finished {
    color: var(--green400);
  }

  &.pending {
    color: var(--gray600);
  }
}