// @import 'antd/dist/antd.variable.min.css';

button {
  cursor: pointer;
  border: none;
  background-color: inherit;
}

h1,
h2,
h3,
p,
span {
  margin: 0;
}

:root {
  --desktop-max-width: 540px;
  --orange50: #FFF8F5;
  --orange100: #FFEAE0;
  --orange200: #FFC8B3;
  --orange300: #FF9466;
  --orange400: #E68819;
  --orange500: #DA7B11;
  --orange600: #CB6F10;
  --orange700: #BD640D;
  --orange800: #FF4800;
  --orange900: #E54100;

  --red100: hsl(357, 100%, 95%);
  --red300: #fed7d6;
  --red400: #E34850;
  --red500: #D7373F;
  --red600: #C9252D;
  --red700: #BB121A;

  --yellow100: #fffbea;
  --yellow200: #fff7d1;
  --yellow300: #ffea9d;
  --yellow400: #ffd230;
  --yellow500: #FAB815;
  --yellow700: #ba9100;
  --yellow900: #825b00;

  --blue100: #e0f5ff;
  --blue200: #b5f5ec;
  --blue300: #2AB9F5;
  --blue400: #1890ff;
  --blue700: #0067c8;
  --blue900: #151d28;

  --green100: #e6fffb;
  --green200: #c8f3b5;
  --green300: #87d068;
  --green400: #52c41a;
  --green700: #2e9601;


  --gray75: #FAFAFA;
  --gray100: #F5F5F5;
  --gray200: #EAEAEA;
  --gray300: #E1E1E1;
  --gray400: #C4C4C4;
  --gray500: #B3B3B3;
  --gray600: #8E8E8E;
  --gray700: #6E6E6E;
  --gray800: #4B4B4B;
  --gray900: #2C2C2C;
  --black: #000000;

  --backdrop: rgba(255, 255, 255, 0.4);
  --unable: rgba(0, 0, 0, 0.4);
  --cardShadow: rgb(0 0 33 / 7%) 0px 16px 22.4px 4.8px, rgb(0 0 33 / 5%) 0px 3.2px 16px 0px, rgb(0 0 33 / 7%) 0px 0px 1px 0px;
  --dndCardShadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.03);
  --white: white;
}

.loading {
  position: fixed;
  top: 0;
  height: 100vh;
  left: 0;
  width: 100vw;
  background-color: var(--backdrop);
}

// ag-grid에서 사용하는 개별 셀에 대한 class
.unknown,
.user {
  color: var(--gray600);
}

.submitted {
  color: var(--blue400);
}

.canceled {
  color: var(--red600);
}

.stock {
  color: var(--gray900);
}

.out-of-stock {
  color: var(--orange700);
}

.discontinued {
  color: red;
}

.refrigerator {
  color: #00bfff;
}

.freezer {
  color: #7614ff;
}

.gridHeader {
  padding: 6px 8px;
  border: solid 1px var(--gray400);
  border-bottom: none;
}

.tableButtons {
  display: flex;
  position: absolute;
  top: 0;
  height: 54px;
  z-index: 1;
  align-items: center;

  button {
    margin-right: 8px;
  }
}

.tabBody {
  padding: 20px;
  background-color: white;
  border: 1px solid rgba(5, 5, 5, 0.06);
  border-top: none;
  overflow: scroll;
}

.order-product-group-row {
  font-weight: 500;
  color: var(--gray900);
}

// Antd 설정
.ant-tabs-tabpane {
  height: 100%;
}

.ant-tabs-content {
  height: 100%;
}

.ant-tabs-content-holder {
  height: 100%;
  overflow: scroll;
}

// Antd notification의 내용이 줄바꿈을 하도록 변경
.pre-line-notification {
  white-space: pre-line;
}

// ProductStockHistoryTable
.stock-cell {
  padding: 4px 8px;
  border-radius: 8px;

  &.inbound,
  &.cancel_outbound {
    color: #2172EB;
    background-color: #E8F3FF;

    &::before {
      font-size: 13px;
      content: '↑ ';
    }
  }

  &.outbound,
  &.cancel_inbound {
    color: #EF4452;
    background-color: #FFEEEE;

    &::before {
      font-size: 13px;
      content: '↓ ';
    }
  }

  &.infinity {
    color: #159697;
    background-color: #EDF8F8;
  }

  &.unchanged {
    color: #8E8E8E;
    background-color: #F5F5F5;
  }
}

.canceled-stock-history {
  opacity: 0.5;

  span {
    text-decoration: line-through;
  }
}

.gray500 {
  color: var(--gray500);
}

.gray600 {
  color: var(--gray600);
}

.green300 {
  color: var(--green300);
}

.red400 {
  color: var(--red400);
}

.bgGray300 {
  background-color: var(--gray300);
}

.bgBlue100 {
  background-color: var(--blue100);
}

.bgBlue200 {
  background-color: var(--blue200);
}

.bgGreen100 {
  background-color: var(--green100);
}

.bgGreen200 {
  background-color: var(--green200);
}

.bgYellow100 {
  background-color: var(--yellow100);
}

.bgYellow200 {
  background-color: var(--yellow200);
}

.bgRed100 {
  background-color: var(--red100);
}

.textAlginRight {
  text-align: right;
}