.dashboardConatiner {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.dashboardToolbar {
  display: flex;
  align-items: center;
  gap: 8px;
}

.packingSheetHeader {
  text-align: right;
  font-size: 16px;
}

.dashboardContent {
  overflow: hidden;
  height: 100%;
}