.orderProductItem {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.name {
  font-weight: 600;
}

.price {
  color: var(--gray700);
}

.warn {
  color: var(--red500);
}