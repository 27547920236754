.partnerMarker {
  position: relative;
  text-rendering: optimizeSpeed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  letter-spacing: -0.4px;
  color: white;
  text-align: center;
  min-width: 50px;
  height: 36px;
  padding: 2px 6px;

  .markerBg {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px 6px 6px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
  }

  p {
    font-size: 10px;
  }

  span {
    font-size: 14px;
    font-weight: 600;
  }

  .markerTail {
    position: absolute;
    bottom: -6px;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 6px 0 0;
    border-color: transparent;
    border-top-color: rgba(0, 0, 0, 0.12);
  }

  .markerShadow {
    position: absolute;
    z-index: -2;
    bottom: -4px;
    left: 4px;
    width: 100%;
    height: 100%;
    border-radius: 6px 6px 6px 0;
    background-color: rgba(0, 0, 0, 0.12);
  }

  &.selected {
    .markerBg {
      border-radius: 0 0 6px 0;
    }

    &::after {
      position: absolute;
      content: '';
      top: -3px;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: #2c2c2c;
    }
  }
}

.basicMarker {
  position: relative;
  border-radius: 50%;
  border: 8px solid;
  width: 24px;
  height: 24px;
}

.basicMarker::after {
  position: absolute;
  content: '';
  width: 0px;
  height: 0px;
  bottom: -30px;
  left: -6px;
  border: 10px solid transparent;
  border-top: 17px solid;
  border-top-color: inherit;
}

.clusterMarker {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  color: #2C2C2C
}