.warning {
  color: var(--red400);
  font-weight: bold;
}

.isError {
  color: var(--red400);
}

.manualModalBody {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

.requestInfo {
  display: flex;
  flex-direction: column;
}

.syncOption {
  padding: 20px 0 10px;
}

.requestInfoItem {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.gridItem {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  font-size: 16px;
  font-weight: bold;
  border-top: 1px solid var(--gray300);
}

.gridItem>div {
  text-align: center;
  padding: 10px;
  border-left: 1px solid var(--gray300);

  &:last-child {
    border-right: 1px solid var(--gray300);
  }
}

.gridItem.footer>div {
  border-left: none;
  border-right: none;
}

.gridItem.footer {
  border-bottom: none;
}

.request {
  background-color: #ffff00;
}

.purchase {
  background-color: #b6e1cd;
}

.volume {
  background-color: #d0e2f4;
}