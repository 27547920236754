.productUploadContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.errorContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  padding: 20px;
  gap: 10px;

  h1 {
    font-size: 24px;
  }

  p {
    font-size: 16px;
  }
}

.errorCode {
  padding: 8px;
  color: var(--red700);
  background-color: var(--red100);

  p {
    font-size: 14px;
    font-weight: 600;
  }
}

.buttons {
  display: flex;
  gap: 8px;
}