.tableContainer {
  :global .notIn {
    color: var(--gray500);
  }

  :global .admin {
    color: #8d0fe8;

    span {
      margin-right: 4px;
    }
  }

  :global .manager {
    color: var(--orange400);

    span {
      margin-right: 4px;
    }
  }
}

.tableButtons {
  display: flex;
  position: absolute;
  top: 0;
  height: 54px;
  z-index: 1;
  align-items: center;

  button {
    margin-right: 8px;
  }
}