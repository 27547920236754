.productStockContainer {
  display: flex;
  flex-direction: column;
}

.toolbarContainer {
  display: flex;
  gap: 10px;

  >div {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }
}

.bodyContainer {
  flex: 1;
  display: flex;
  gap: 10px;
}