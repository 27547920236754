.searchContainer {
  position: relative;
}

.searchBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;

  :global .ant-input-affix-wrapper {
    border-radius: 4px 0 0 4px !important;
  }

  :global .ant-input-search-button {
    border-radius: 0 4px 4px 0 !important;
  }
}

.searchPreview {
  position: absolute;
  top: 42px;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 1;
  max-height: 240px;
  overflow: scroll;
  box-shadow: 0 6px 16px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%), 0 9px 28px 8px rgb(0 0 0 / 5%);

  p {
    padding: 12px 16px;
    margin: 0;
    font-size: 14px;
    color: var(--gray700);
    cursor: pointer;

    span {
      padding-right: 4px;
    }

    &:hover,
    &.selected {
      color: var(--gray900);
      background-color: var(--gray100);
    }
  }
}

.hide {
  display: none;
}