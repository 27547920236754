.messageContainer {
  max-width: 80%;

  &.sent {
    margin-left: auto;
    text-align: right;

    .messageBubble {
      background-color: #d1e7dd;
    }
  }

  &.received {
    margin-right: auto;
    text-align: left;

    .messageBubble {
      background-color: #f8d7da;
    }
  }
}

.messageBubble {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  display: inline-block;
  /* 메시지 내용에 맞게 너비 조절 */


  strong {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
}