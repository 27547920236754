$titleSize: 38px;
$titleFontSize: 14px;
$tableBorder: 1px solid var(--gray300);
$rowFontSize: 16px;

.storeIssuePrintContainer {
  background-color: white;
  padding: 10px;
  height: 100%;
  overflow: scroll;
}

.gridRow {
  display: grid;
  grid-template-columns: 1fr .5fr 1.5fr 2fr 2fr;
  border-bottom: $tableBorder;
  font-size: $rowFontSize;
  line-height: 1.5;

  >div {
    padding: 8px;
    border-right: $tableBorder;
    word-break: keep-all;

    &:last-child {
      border-right: none;
    }
  }
}

.gridHeader {
  background-color: var(--gray200);
  font-size: 14px;
  font-weight: 600;
}

.memo {
  font-weight: 600;
}