.billContainer {
  display: inline-block;
  min-width: 480px;
  width: 480px;

  h2 {
    font-size: 14px;
    text-align: left;
    padding: 0 14px 8px;
  }
}

.withText {
  .billImageContainer {
    min-height: 639px;
  }

  .message {
    white-space: pre-line;
    font-size: 16px;
    padding: 20px 16px 16px;
    margin-top: 20px;
    border-top: 1px solid var(--gray300);
  }
}

.receiver {
  padding-bottom: 5px;
}

.message {
  padding-top: 5px;
}

.error {
  .receiver {
    font-weight: 700;
    color: var(--red500);
  }
}

.billImageContainer {
  background-color: white;
  height: auto;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding-bottom: 16px;
}

.top {
  padding: 12px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;

  &.warn {
    background-color: var(--red100);
    color: var(--red500);
  }
}

.summary {
  margin: 8px 16px 14px;
  border: 1px solid var(--gray300);
  border-radius: 4px;
  color: var(--gray900);
}

.item {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 16px;
  border-bottom: 1px solid var(--gray300);

  &:last-child {
    border-bottom: none;
  }

  &>div:last-child {
    text-align: right;
    flex: 1;
  }

  .label {
    font-weight: 400;
  }

  .settlementAmount {
    font-size: 28px;
    font-weight: 700;

    span {
      padding-left: 6px;
      font-size: 16px;
    }
  }
}

.details {
  margin: 0 16px;
  border: 1px solid var(--gray300);
  border-radius: 4px;
  color: var(--gray900);
}

.detailItem {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 4px 10px;
  border-bottom: 1px solid var(--gray300);

  &.header {
    background-color: var(--gray100);
  }

  &.attention {
    color: var(--red500);
    font-weight: 700;
  }

  &.sum {
    border-bottom: 1px solid var(--gray400);
  }

  &.total {
    border-top: 1px solid var(--gray400);
    font-weight: 700;
    background-color: var(--yellow200);
  }

  &:last-child {
    border-bottom: none;
  }

  &>div {
    text-align: right;
    padding-right: 8px;

    &:first-child {
      padding-left: 8px;
      padding-right: 0;
      text-align: left;
    }
  }
}

.total {
  font-weight: 600;
}

.extraItem {
  display: flex;
  padding: 6px 10px;

  .price {
    text-align: right;
    padding-right: 8px;
    font-weight: bold;
  }

  &>div {
    padding-left: 8px;
    flex: 1;
  }
}