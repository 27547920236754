.unregistered {
  background-color: #ff9f9f;
}

.spotCard {
  font-family: Pretendard;

  p {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 20px;
    color: var(--gray800)
  }

  .cardTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h4 {
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
      margin: 0;
    }
  }

  .summary {
    margin-top: 6px;
  }

  .orderAmount {
    margin-left: 2px;
    font-weight: 500;
  }

  .issue {
    margin-left: 4px;
    line-height: 16px;
    font-size: 10px;
    font-weight: 600;
    color: var(--gray900);
    border-radius: 8px;
    padding: 0 5px;
    background-color: var(--gray400);

    &.회수 {
      background-color: var(--yellow400);
    }

    &.배송 {
      background-color: var(--green300);
    }
  }

  .amount {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
    padding-top: 8px;
    border-top: 1px dashed var(--gray300);
    font-size: 14px;
    line-height: 18px;
    color: var(--gray800);

    .totalAmount {
      font-weight: 700;
      color: var(--gray900);
    }
  }
}

.issueCount {
  color: var(--gray600);
}