.settlementContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 8px;

  header {
    display: flex;
    gap: 10px;
  }

  section {
    flex: 1;
  }
}