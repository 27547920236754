.printWindowContainer {
  position: relative;
  box-sizing: border-box;
  width: 210mm;
  overflow: scroll;
  height: 100%;

  @media print {
    width: 100%;
  }
}

.toolbar {
  position: sticky;
  top: 0;
  z-index: 10;
  opacity: 0.5;

  .printButton {
    position: absolute;
    top: 0;
    right: 0;
  }
}

// print와 margin값
// https://stackoverflow.com/questions/1960939/disabling-browser-print-options-headers-footers-margins-from-page
@page {
  size: A4;
  margin: 10mm 5mm;
}