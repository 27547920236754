.partnersTableContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
}

.buttons {
  display: flex;
  justify-content: flex-end;
}

.tables {
  display: flex;
  gap: 8px;
  height: 100%;
}