.tags {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 6px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
  min-height: 44px;
}

.emptyMessage {
  width: 100%;
  font-size: 14px;
  color: #888;
  text-align: center;
  padding: 10px 0;
  border: 1px dashed #ccc;
  border-radius: 4px;
}

.keyValueTagForm {
  width: 320px;
  margin: 20px;
}

.actionButtons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.button {
  flex: 1;
}