.appConfigContainer {
  background-color: white;
  padding: 20px;

  h2 {
    margin: 20px 0 10px;
  }
}

.formContainer {
  border: 1px solid var(--gray200);
  border-radius: 8px;
  padding: 20px;
  max-width: 800px;

  &.locked {
    opacity: 0.5;
  }
}