.chatRoom {
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* 전체 화면을 채우도록 설정 */
  padding: 20px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.messages {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ddd;
}

.messageInput {
  display: flex;
}

.messageInput input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.messageInput button {
  padding: 10px;
  border: none;
  background-color: #0d6efd;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}