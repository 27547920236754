$imgPadding: 4px;

.imageEditorContainer {
  position: relative;
  display: flex;
  height: 100%;
  padding: $imgPadding;
  background-color: white;
  border: 1px solid var(--gray200);
  border-radius: 2px;

  &::before {
    position: absolute;
    z-index: 1;
    width: calc(100% - ($imgPadding * 2));
    height: calc(100% - ($imgPadding * 2));
    background-color: rgba(0, 0, 0, .45);
    opacity: 0;
    transition: opacity .3s;
    content: " ";
  }

  &.uploadMode::before {
    background-color: transparent;
    border: 1px dashed var(--blue400);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.imageEditorContainer {
  input {
    outline: none;
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
}

.imageEditorContainer:hover {
  &::before {
    opacity: 1;
  }

  .imageEditorButtons {
    opacity: 1;
  }
}

.imageEditorButtons {
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  height: 100%;
  z-index: 2;
  padding: $imgPadding;
  opacity: 0;
  transition: opacity .3s;

  span {
    color: white;
  }

  &.forImageUrl {
    flex-direction: column;
    gap: 4px;
  }
}

.imageBox {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.inputLabel {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  z-index: 1;
  color: var(--gray700);

  span {
    font-size: 18px;
  }

  p {
    font-size: 12px;
    line-height: 1;
  }
}