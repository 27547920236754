$borderStyle: 1px solid #5071BE;
$lightBorderStyle: 1px solid var(--gray200);
$bgColor: #DBE3F2;

.purchaseOrderPrintList {
  width: 100%;
  background-color: white;
  font-size: 16px;
  font-weight: 700;

  p {
    padding: 5px 8px;
  }
}

.header,
.footer {
  display: grid;
  grid-template-columns: 1fr 3fr;
  border-bottom: 2px solid var(--gray700);
  background-color: $bgColor;

  p {
    border-left: $lightBorderStyle;
  }
}

.purchaseOrderItem {
  font-size: 14px;
  display: grid;
  grid-template-columns: 1fr 3fr;
  border-bottom: $borderStyle;
}

.orderMessage {
  border-left: $lightBorderStyle;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  white-space: break-spaces;
}