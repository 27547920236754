.supplierTableContainer {
  height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
}

.toolbar {
  display: flex;
  gap: 6px;
  margin-bottom: 8px;
}