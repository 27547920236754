body {
  font-family: Pretendard;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.height100 {
  height: 100%;
}

.bgWhite {
  background-color: white;
}

.width100 {
  width: 100%;
}

.flex1 {
  flex: 1;
}

.flex2 {
  flex: 2;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.flexRow {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.flexEnd {
  justify-content: flex-end;
}

.flexAlignCenter {
  align-items: center;
}

.flexSpaceBetween {
  justify-content: space-between;
}

.textOverFlow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


/**********************************************/
/*                AG Grid CSS                 */
/**********************************************/
.ag-theme-alpine {
  --ag-data-color: var(--gray800);
}

/**********************************************/
/*                Print CSS                   */
/**********************************************/
@media print {
  .no-print {
    display: none !important;
  }

  #app {
    display: none;
  }
}

#print-window {
  display: none;

  @media print {
    display: block !important;
  }
}