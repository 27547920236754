.modalContentContainer {
  padding-top: 16px;
  height: calc(100vh - 280px);
  overflow: scroll;
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.leftSection {
  flex: 1;
  border-top: 1px solid var(--gray200);
}

.rightSection {
  flex: 1;
  border-top: 1px solid var(--gray200);
  max-width: 480px;
}