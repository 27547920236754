.accessMethodGroup {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 16px;
  gap: 10px;
  margin-bottom: 10px;
  background-color: var(--gray200);
}

.accessMethod {
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--gray400);
  align-items: center;
  gap: 4px;
}

.accessMethodImg {
  width: 24px;
  height: 24px;
}

.accessMethodTitle {
  font-size: 14px;
  font-weight: bold;
  color: var(--black);
}

.accessMethodStore {
  font-size: 14px;
  color: var(--gray900);
}