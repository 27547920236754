.userInfoContainer {
  display: flex;
  padding: 20px;
  gap: 20px;
}

.flexColumn {
  display: flex;
  flex: 1;
  overflow: hidden;
  flex-direction: column;
  gap: 12px;
  max-width: 720px;
}

.flexRow {
  display: flex;
  font-size: 14px;
  gap: 6px;

  .label {
    font-weight: 400;
    flex: 1;
    color: var(--gray900);

    .subLabel {
      font-size: 12px;
      color: var(--gray700);
    }
  }

  .value {
    flex: 2;
    word-break: break-all;
    font-weight: 500;
  }
}