.appConfigContainer {
  background-color: white;
  padding: 20px;

  h2 {
    margin: 20px 0 10px;
  }
}

.formContainer {
  border: 1px solid var(--gray200);
  border-radius: 8px;
  padding: 20px;
  max-width: 800px;
  display: flex;
  flex-direction: column;

  &.locked {
    opacity: 0.5;
  }
}

.buttonWithLabel {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 20px;

  .labelText {
    flex-basis: 33.3333%;
    text-align: right;
    padding-right: 4px;
  }
}