.storeTableContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 8px;

  header {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
  }

  section {
    flex: 1;
  }
}