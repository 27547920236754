.packingInfoDashboardContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
}

.dashboardToolbar {
  display: flex;
  gap: 8px;
}

.buttons {
  display: flex;
  gap: 8px;
}