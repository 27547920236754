.userDetailContainer {
  overflow: hidden;
  flex: 1;

  :global .ant-tabs-content-holder {
    border: 1px solid rgba(5, 5, 5, 0.06);
    border-top: transparent;
    background-color: white;
  }
}

.userDetailHead {
  padding-bottom: 10px;
}