.taskOrderInfoBox {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.labelWithText {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid var(--gray400);
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: var(--gray700);
  padding: 6px 0;

  &.bold {
    border-color: var(--gray900);
    font-weight: 600;
    color: var(--gray900);
    font-size: 15px;
  }
}

.titleBox {
  font-size: 15px;
  color: var(--gray900);
  padding: 12px 0;
  border-bottom: 1px dashed var(--gray300);
}

.contentBox {
  padding-top: 6px;
  height: 100%;
  overflow: scroll;
}

.taskIssue {
  max-width: 240px;
  font-weight: 500;
  color: var(--red500);
  text-align: right;
}