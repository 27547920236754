.boardContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px;
  background-color: var(--gray200);
  gap: 10px;
  height: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
}