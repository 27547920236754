.routeBoardContainer {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-bottom: 2px dashed var(--gray300);
}

.routeBoardHeader {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  background-color: var(--gray100);
}

.options {
  display: flex;
  gap: 8px;
  align-items: center;
}

.invalidItems {
  display: inline-block;
  padding: 4px;
  background-color: var(--red100);
  color: var(--red400);
  font-weight: bold;
}