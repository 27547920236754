.orderViewContent {
  background-color: var(--gray100);
  padding: 6px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.outOfStock {
  margin-left: 8px;
  color: var(--red400);
}

.changeVolume {
  margin-left: 8px;
  color: var(--red400);
}

.afterPrice {
  color: var(--blue400);
  font-weight: 500;
}