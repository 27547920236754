.flexRow {
  display: flex;
  font-size: 14px;
  gap: 6px;

  .label {
    font-weight: 400;
    flex: 1;
    color: var(--gray900);

    .description {
      font-size: 12px;
      color: var(--gray700);
    }
  }

  .value {
    position: relative;
    flex: 2;
    word-break: break-all;
    font-weight: 500;
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 32px;
  background-color: var(--backdrop);
}

.onEditButton {
  position: absolute;
  top: 0;
  right: 0;
}