.userOrderListContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 20px;

  :global .ant-tabs-content-holder {
    border: none;
  }
}

.summary {
  font-size: 14px;
  margin-bottom: 10px;
  border-collapse: collapse;

  span {
    font-size: 24px;
    font-weight: 700;
    color: var(--gray900);
  }

  td,
  th {
    text-align: left;
  }
}