.trendLabel {
  display: flex;
  flex-direction: column;
}

.trendValue {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.valueCell {
  text-align: right;
  padding: 0 16px;
}