$white: #fff;

$gray75: #fafafa;
$gray100: #f5f5f5;
$gray200: #eaeaea;
$gray300: #e1e1e1;
$gray400: #cacaca;
$gray500: #b3b3b3;
$gray600: #838383;
$gray700: #6e6e6e;
$gray800: #4b4b4b;
$gray900: #2c2c2c;

$yellow200: #ffeb99;
$yellow300: #ffd966;
$yellow400: #ffcc00;

$orange400: #ff6600;

$blue400: #2680eb;
$blue500: #1473e6;
$blue600: #0d66d0;
$blue700: #095aba;

$red400: #FF3B30;