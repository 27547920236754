$borderStyle: 1px solid #5071BE;
$lightBorderStyle: 1px solid var(--gray200);
$bgColor: #DBE3F2;

.eggProductList {
  width: 100%;
  background-color: white;
  font-size: 16px;
  font-weight: 700;

  p {
    padding: 4px 8px;
  }

  break-after: page;
}

.header,
.footer {
  display: grid;
  grid-template-columns: 2fr repeat(3, 1fr);
  border-bottom: 2px solid var(--gray700);
  background-color: $bgColor;

  p {
    border-left: $lightBorderStyle;
  }

  p:last-child {
    border-left: $borderStyle;
  }
}

.productItem {
  display: grid;
  grid-template-columns: 2fr 3fr;
  border-bottom: 2px solid var(--gray700);
}

.partnerItem {
  display: grid;
  grid-template-columns: 1fr 2fr;
  border-left: $lightBorderStyle;

  p {
    border-bottom: $borderStyle;
  }

  &:last-child {
    p {
      border-bottom: none;
    }

    .bundleGroupItem {
      border-bottom: none;
    }
  }
}

.bundleGroupItem {
  border-bottom: $borderStyle;
}

.bundleItem {
  display: grid;
  grid-template-columns: 1fr 1fr;

  span {
    padding: 5px 8px;
    border-left: $borderStyle;
    border-bottom: $lightBorderStyle;
  }

  span:first-child {
    border-left: $lightBorderStyle;
  }

  &:last-child {
    span {
      border-bottom: none;
    }
  }
}