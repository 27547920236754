.checkListContainer {
  width: 360px;
  min-width: 360px;
  background-color: white;
  border-radius: 8px;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: var(--dndCardShadow);
}

.scrollList {
  flex: 1;
  overflow: scroll;
}

.partnerName {
  padding: 20px 20px 10px 20px;
  font-size: 18px;
  font-weight: bold;
  color: var(--gray900);
}

.empty {
  font-size: 16px;
  color: var(--gray700);
  text-align: center;
  margin-top: 20px;
}

.errorBox {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.section {
  padding: 16px 20px;
}

.divider {
  height: 16px;
  background-color: var(--gray100);
}

.sectionTitle {
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0;
  color: var(--black);
}