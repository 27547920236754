.spotColumnHeader {
  // background-color: var(--gray75);
  padding: 8px 10px;
  border-radius: 4px;
  border: 1px solid var(--gray300);
  line-height: 1.5;
}

.spotCount {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  div {
    display: flex;
    align-items: center;
    gap: 8px; // justify-content: space-between;
  }
}

.totalAmount {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2px;
}

.label {
  font-size: 13px;
  color: var(--gray800);
}