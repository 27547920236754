.whiteBoard {
  width: 236px;
  padding: 8px;
  background-color: white;
  border-radius: 4px;

  p {
    line-height: 1.5;
    white-space: pre-wrap;
  }
}

.alimtalkButtons {
  padding-top: 8px;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: var(--gray100);
    height: 32px;
    color: var(--gray900);
  }
}