$borderStyle: 1px solid #5071BE;
$lightBorderStyle: 1px solid var(--gray200);
$bgColor: #DBE3F2;

.productsForSupplierList {
  width: 100%;
  background-color: white;
  font-size: 16px;
  font-weight: 700;

  p {
    padding: 5px 8px;
  }
}

.header,
.footer {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr 0.8fr;
  border-bottom: 2px solid var(--gray700);
  background-color: $bgColor;

  p {
    border-left: $lightBorderStyle;
  }

  p:last-child {
    border-left: $borderStyle;
  }
}

.supplierItem {
  display: grid;
  grid-template-columns: 1fr 4.8fr;
  border-bottom: $borderStyle;
}

.productItem {
  display: grid;
  grid-template-columns: 3fr 1.8fr;
}

.productItem>div,
.productItem>p {
  border-bottom: $borderStyle;

  &:last-of-type {
    border-bottom: none;
  }
}

.partnerItem {
  display: grid;
  grid-template-columns: 1fr 0.8fr;
}

.volume {
  border-left: $borderStyle;
}

.productName {
  letter-spacing: -0.025em;
}