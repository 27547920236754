.partnerInfoRowContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid var(--gray200);
  padding: 12px 0;
}

.partnerColorBox {
  width: 16px;
  height: 36px;
  border: 1px solid var(--gray200);
}

.partnerContent {
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: var(--gray700);
  }

  b {
    display: flex;
    align-items: center;
    font-size: 15px;
    gap: 4px;
    color: var(--gray900);
  }
}

.status {
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  padding: 0 4px;
  color: var(--gray700);
  border: 1px solid var(--gray200);
  border-radius: 12px;

  &.active {
    color: var(--green700);
    border-color: var(--green400);
  }
}