.recipient,
.recipientHeader {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  padding: 6px 0;
  text-align: center;
}

.recipientHeader {
  background-color: var(--gray100);
}