.markerPopover {
  position: absolute;
  z-index: 10;
  bottom: 42px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  border: 1px solid var(--gray500);
  border-radius: 6px;
  min-width: 160px;

  display: flex;
  flex-direction: column;
  background-color: white;
}

.toolbar {
  width: 100%;
  padding: 4px;
  display: flex;
  justify-content: flex-end;
}

.content {
  padding: 0 8px 8px;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 10px;

  :global .ant-select {
    width: 100%;
  }
}

.sortKeyInputBox {
  display: flex;
  align-items: center;
  gap: 4px;
}