.sectionContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  overflow: hidden;
  height: 100%;

  section {
    flex: 1;
    max-width: 430px;
    border: 1px solid #e0e0e0;
    padding: 10px;
    background-color: var(--gray100);
    height: 100%;
    overflow-y: scroll;
    box-sizing: border-box;
  }

  h3 {
    margin: 0;
    padding: 6px 0;
  }
}