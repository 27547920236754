.dashboardContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.buttonToolbar {
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
}

.buttons {
  display: flex;
  gap: 8px;
  align-items: flex-end;
}