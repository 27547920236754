.boardContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

.boardToolbar {
  position: sticky;
  z-index: 10;
  top: 0;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 6px;
  background-color: var(--gray100);
  border-bottom: 2px dashed var(--gray300);

  .buttons,
  .options {
    display: flex;
    gap: 8px;
    align-items: center;
  }
}

.kanbanBoard {
  flex: 1;
  display: flex;
  width: 100%;
}

.leftSection {
  display: flex;
  flex-grow: 1;
  gap: 10px;
  overflow: scroll;
  padding-right: 10px;
}

.horizontal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30% 8px 0 4px;
  border-left: 1px solid var(--gray300);

  .bar {
    width: 1px;
    height: 20px;
    border-left: 2px solid var(--gray400);
  }
}

.rightSection {
  display: flex;
}