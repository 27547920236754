.dndCard {
  position: relative;
  background-color: white;
  border: 1px solid var(--gray200);
  border-radius: 8px;
  padding: 10px 14px;
  box-shadow: var(--dndCardShadow);
  cursor: grab;

  &.selected::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 3px solid var(--blue400);
    border-radius: 8px;
  }
}

.insertLineTop,
.insertLineBottom {
  position: absolute;
  height: 3px;
  background-color: var(--orange800);
  width: 100%;
  margin-left: -14px;
}

.insertLineTop {
  top: -3px;
}

.insertLineBottom {
  bottom: -5px;
}