.installmentBillUploadContainer {
  height: 100%;
}

.uploadedFileContainer {
  display: flex;
  flex-direction: column;
  height: 100%;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
}

.uploadedFile {
  display: flex;
  align-items: center;
  padding: 8px 12px 8px 16px;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid var(--gray300);

  p {
    font-size: 14px;
    font-weight: 600;
  }
}

.sections {
  flex: 1;
  display: flex;
  overflow: hidden;
  gap: 20px;

  section {
    box-sizing: border-box;
    height: 100%;
    border: 1px solid var(--gray300);
  }
}

.action {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.actionHeader {
  position: sticky;
  top: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray300);

  .total {
    font-size: 14px;
  }

  .invalid b {
    color: var(--red500);
  }

  &>div {
    padding: 8px 10px;
  }

  .bottom {
    border-top: 1px solid var(--gray300);
    background-color: var(--gray100);
    padding-top: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
  }
}

.billList {
  overflow: scroll;
  position: relative;

  .header {
    padding: 6px 10px;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 10;
    border-bottom: 1px solid var(--gray300);
  }
}

.itemHeader {
  border-bottom: 1px solid var(--gray300);
}

.billItem {
  display: grid;
  padding: 6px 8px;
  grid-template-columns: 1fr 3fr 2fr 1fr 2fr;
  gap: 4px;
  align-items: center;

  &:nth-child(odd) {
    background-color: var(--gray100);
  }

  .alignCenter {
    text-align: center;
  }

  &.manual {
    color: var(--red500);
  }
}

.checkboxGroup {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.preview {
  background-color: var(--gray100);
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 520px;
  padding: 20px;
  overflow-y: scroll;
  height: 100%;
}