.mapContainer {
  position: relative;
}

.mapFloatButtons {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  padding: 5px;
}

.mapLoadingOverlay {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: #ffffff, $alpha: 0.4);

  span {
    color: var(--blue400);
  }
}