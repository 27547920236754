.formContainer {
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin: 20px 0;
  gap: 20px;
}

.preview {
  width: 400px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid var(--gray200);
  display: flex;
  flex-direction: column;
}

.notiItem {
  padding: 20px;
  display: flex;
}

.notiIcon {
  margin-top: 4px;
  margin-right: 12px;

  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: var(--orange400);
  }
}

.notiContent {
  flex: 1;

  p {
    font-size: 16px;
    letter-spacing: -0.025em;
    color: var(--gray900);
  }

  div {
    width: 100%;
    font-size: 13px;
    color: var(--gray600);
    line-height: 1.5715;
  }

  .notiInfo {
    font-weight: 600;
  }
}