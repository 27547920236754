.orderContainer {
  display: flex;
  flex-direction: column;
  height: 100%;

  :global .ant-tabs-content-holder {
    border: 1px solid rgba(5, 5, 5, 0.06);
    border-top: transparent;
    background-color: white;
  }
}