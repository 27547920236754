$titleSize: 38px;
$titleFontSize: 14px;
$tableBorder: 1px solid var(--gray300);
$productFontSize: 16px;

.packingSheetContainer {
  background-color: white;
  padding: 10px;
  height: 100%;
}

.deliverySpot {
  position: relative;
  border: 2px solid var(--gray900);

  .spotTitle {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
    background-color: var(--orange200);
    color: var(--orange900);
    font-weight: 800;
    width: calc(100% - 10px);
    text-align: right;
    line-height: $titleSize;
    font-size: $titleFontSize;
    padding-right: 8px;
  }
}

.divider {
  width: 100%;
  height: 20px;
}

.storeCard {
  position: relative;
  padding-top: $titleSize;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  .storeName {
    position: absolute;
    top: 0;
    z-index: 1;
    font-size: $titleFontSize;
    line-height: $titleSize;
    background-color: white;
    padding: 0 16px 0 10px;
    border: 1px solid var(--gray400);
    border-bottom: 1px solid white;
    border-radius: 0 20px 0 0;
    color: var(--gray800);
  }
}

.gridContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: white;
  border: 1px solid var(--gray400);
  padding-top: 4px;

  .gridItem {
    border-right: $tableBorder;

    &:last-child {
      border-right: none;
    }
  }
}

.flexContainer {
  display: flex;
  align-items: center;
  border-bottom: $tableBorder;
  font-size: $productFontSize;

  &.indoors {
    .label {
      color: #ff0000;
    }
  }

  .itemLabel {
    flex: 1;
    border-right: $tableBorder;
    padding: 2px 10px;
    font-size: 17px;
    font-weight: 800;
    display: flex;
    justify-content: space-between;

    .label {
      flex: 3;
    }

    .productFeature {
      flex: 1;
      text-align: right;
      color: #AB22FF;
    }

    // &.room {

    // }
    &.refrigerator {
      background-color: #CDFDC5;
    }

    &.freezer {
      background-color: #DCF2FE;
    }
  }

  .itemQuantity {
    width: 36px;
    font-weight: 700;
    text-align: center;
  }
}