.signinContainer {
  position: relative;
  padding: 28px;
  background-color: white;
  margin: 0 auto;
  max-width: 480px;
  box-shadow: var(--cardShadow);
  border-radius: 16px;
}

.submitSigninButton {
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  height: 52px;
  margin-top: 20px;
}

.loadingBox {
  position: fixed;
  font-size: 42px;
  color: var(--orange500);
  top: calc(50% - 60px);
  left: calc(50% - 21px);
}

.textButton {
  color: #40a9ff;
}