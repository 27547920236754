.uploadModalContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.uploadFile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.tableContainer {
  flex: 1;
}

.loadingBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loadingIcon {
  margin-top: 16px;
  font-size: 4em;
  color: var(--blue400);
}