.deliverySpotContainer {
  position: relative;
  display: flex;
  height: 100%;
  overflow: hidden;
}

.leftSection {
  flex: 1;
  overflow-y: scroll;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.options {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 8px;
}

.option {
  display: flex;
  align-items: center;
  gap: 4px;
}

.midSection {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rightSection {
  position: relative;
  flex: 2;
}

.mapLoadingOverlay {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: #ffffff, $alpha: 0.4);

  span {
    color: var(--blue400);
  }
}

.mapFloatButtons {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  padding: 5px;
}