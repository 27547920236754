.tableContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
}

.stock {
  color: var(--gray900);
}

.out-of-stock {
  color: var(--orange700);
}

.discontinued {
  color: red;
}

.refrigerator {
  color: #00bfff;
}

.freezer {
  color: #7614ff;
}

.purchaseItemHeader {
  background-color: var(--blue200);
}