.uploadArea {
  height: 100%;

  label {
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
    border: 1px dashed var(--gray400);
    box-sizing: border-box;
  }

  span {
    font-size: 3.25rem;
    margin-bottom: 1rem;
    color: hsl(183, 47%, 59%);
    transition: color 0.3s ease-in-out;
  }

  &.file-over {
    label {
      background-color: hsl(193, 42%, 93%);
      border-color: hsl(193, 42%, 60%);
    }

    span {
      color: hsl(193, 43%, 50%);
    }
  }
}

.dropZone {
  z-index: 1;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.noneDisplayInput {
  outline: none;
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}