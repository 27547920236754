.invoiceContainer {
  break-after: page;
  padding-top: 10px;
  background-color: white;

  &:last-child {
    break-after: auto;
  }

  h1 {
    font-size: 18px;
    text-align: center;
  }
}

.invoiceHeader {
  display: flex;
  margin: 10px;
}

.headerBox {
  flex: 1;
  display: grid;
  grid-template-columns: 32px 1fr;
  font-weight: 600;
  font-size: 14px;
  border-top: 1px solid var(--orange400);
  border-bottom: 1px solid var(--orange400);

  &:first-child {
    border-right: 1px solid var(--orange400);
  }

  h3,
  p {
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .target {
    display: flex;
    padding: 8px;
    text-align: center;
    align-items: center;
    border-right: 1px solid var(--orange400);
  }
}

.businessInfo {
  display: flex;
  flex-direction: column;

  .gridItem {
    flex: 1;
    display: grid;
    grid-template-columns: 1fr 3fr;
    border-bottom: 1px solid var(--orange400);

    &:last-child {
      border-bottom: none;
    }

    &> :first-child {
      border-right: 1px solid var(--orange400);
    }

    p {
      padding: 8px;
      word-break: keep-all;
    }
  }

  .flexItem {
    display: flex;
    justify-content: space-around;

    &> :first-child {
      flex: 1;
      border-right: 1px solid var(--orange400);
    }

    &> :last-child {
      min-width: 80px;
    }
  }
}

.detail {
  display: flex;
  flex-direction: column;
  margin: 10px;
  border-top: 2px solid var(--orange700);
  border-bottom: 2px solid var(--orange400);
}

.summary {
  margin: 10px;
  display: grid;
  grid-template-columns: 9fr 1.7fr 1.3fr 1.8fr;
  border-top: 2px solid var(--orange400);
  border-bottom: 2px solid var(--orange400);
  font-size: 15px;
  font-weight: 600;
  text-align: right;

  .title {
    text-align: center;
  }

  &>div {
    padding: 8px 5px;
    border-right: 1px solid var(--gray400);

    &:last-child {
      border-right: none;
    }
  }
}

.detailHeader,
.detailBodyRow {
  display: grid;
  grid-template-columns: 0.6fr 6.2fr 0.7fr 1.5fr 1.7fr 1.3fr 1.8fr;
  text-align: center;
  border-bottom: 1px solid var(--gray400);
  font-weight: 400;
  font-size: 14px;

  &>div {
    padding: 7px 4px;
    border-right: 1px solid var(--gray400);

    &:last-child {
      border-right: none;
    }
  }
}

.detailHeader {
  font-weight: 700;
}

.detailBodyRow {
  &:last-child {
    border-bottom: none;
  }
}

.number {
  text-align: right;
}