.invoiceDashboardContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
}

.dashboardToolbar {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.dashboardContent {
  display: flex;
  flex: 1;
  gap: 8px;
  overflow: hidden;
}

.buttons {
  display: flex;
  gap: 8px;
}