.orderMonthGroup {
  display: inline-block
}

.month {
  font-weight: 600;
}

.totalAmount {
  font-weight: 600;
}

.avgUnit {
  color: var(--blue400);
}