.listRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--gray300);

  &:last-child {
    border-bottom: none;
  }
}

.titleAndSubtitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;
  padding: 9px 0;
}

.title {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.03em;
}

.subtitle {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.03em;
  color: rgba(60, 60, 67, 0.6);
}

.accessories {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.03em;
  color: rgba(60, 60, 67, 0.6);
}