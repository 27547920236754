@import '../../theme/colors.scss';

.logo {
  display: block;
  padding: 16px;
  border-right: 1px solid #f0f0f0;
  color: $white;
  background-color: var(--orange400);
  font-weight: 700;

  &.dev {
    background-color: var(--gray900);
  }

  &:hover {
    color: var(--blue300);
  }

  img,
  span {
    line-height: 20px;
    height: 20px;
  }

  img {
    width: 100%;
  }
}

.menuItem:last-of-type {
  margin-bottom: 80px;
}