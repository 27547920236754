.tip {
  font-size: 13px;
  padding: 4px 2px;
  color: var(--gray700);
  margin-bottom: 20px;
}

.editOrderContainer {
  display: flex;
  gap: 16px;
  height: calc(100vh - 240px);
}

.searchPanel {
  flex: 3;
  display: flex;
  flex-direction: column;
}

.cartPanel {
  flex: 4;
  display: flex;
  flex-direction: column;
}

.marginBox {
  height: 16px;
}

.storeInfo {
  margin: 10px 0 16px;
}

.actions {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.cartContainer {
  padding: 0 16px 8px;
  border: 1px solid var(--gray200);
  border-radius: 4px;
  overflow-y: scroll;
  flex: 1;
}

.summary {
  padding-top: 8px;
}

.tableButtons {
  display: flex;
  position: absolute;
  top: 0;
  height: 54px;
  z-index: 1;
  align-items: center;

  button {
    margin-right: 8px;
  }
}