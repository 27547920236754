.columnContainer {
  display: flex;
  flex-direction: column;
}

.column {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 240px;
  border-radius: 6px;
  padding: 6px;
  padding-bottom: 40px;
  color: var(--gray900);
  overflow-y: scroll;
  background-color: white;
}

.columnEmpty {
  background-color: inherit;
  border: 2px dashed var(--gray400);
}

.emptyText {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  color: var(--gray500);
}

.columnHeader {
  margin: 10px 0;

  h3 {
    font-size: 16px;
    line-height: 30px;
  }
}

.columnBody {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.isOver {
  background-color: var(--orange200);
}