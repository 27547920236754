.checkListContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.listContent {
  position: absolute;
  z-index: 10;
  top: 36px;
  right: 0;
  display: none;
  padding: 8px;
  background: white;
  border: 1px solid var(--gray300);
  border-radius: 6px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);

  &.open {
    display: flex;
  }
}

.checkItem {
  padding: 2px;
}

.inactive {
  color: var(--gray600);
}