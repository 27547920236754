.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-bottom: 1px solid var(--gray300);
  padding: 20px 0;

  &:last-child {
    border-bottom: none;
  }
}

.storeName {
  font-size: 15px;
  font-weight: bold;
  color: var(--gray600);
}

.note {
  font-size: 15px;
  line-height: 21px;
  color: var(--black);
}