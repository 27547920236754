.notificationContaincer {
  padding: 30px;
  background-color: white;

  h1 {
    font-size: 32px;
    margin-bottom: 16px;
  }

  h2 {
    font-size: 24px;
    margin-bottom: 16px;
    font-weight: 600;
  }

  p {
    line-height: 1.5;
  }

  section {
    margin: 40px 0 20px;
  }
}

.dailyTalkContainer {
  display: flex;
  align-items: flex-start;
  padding: 30px 0 20px;
  gap: 60px;

  .right {
    padding: 20px;
    background-color: #bacee1;
    border-radius: 12px;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.2);

    .messageSample {
      width: 236px;
      background-color: white;
      padding: 8px;
      border-radius: 4px;
    }
  }
}

.dailyPriceContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ratioTip {
  margin: 8px 0 30px;
  color: var(--gray800);
}

.recentResponse {
  padding-top: 6px;
  font-weight: 600;

  &.success {
    color: var(--blue400);
  }

  &.error {
    color: var(--red400);
  }
}

.divider {
  margin: 40px 0;
  border-bottom: 1px solid var(--gray200);
}