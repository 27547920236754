.taskLocationRowContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  padding: 14px 0;
  border-bottom: 1px dashed var(--gray200);
  margin-bottom: 16px;

  p {
    margin: 0;
    font-size: 15px;
    font-weight: 500;
    color: var(--gray800);
  }
}

.taskStatus {
  font-weight: 600;

  &.canceled {
    color: var(--red500);
  }

  &.finished {
    color: var(--green400);
  }

  &.pending {
    color: var(--gray600);
  }
}

.arrowBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  font-size: 13px;
  font-weight: 700;
  color: var(--gray800);
}

.arrow {
  font-size: 18px;
  font-weight: 500;
}

.locationCard {
  display: flex;
  align-items: flex-end;
  padding: 8px 12px;
  border: 1px solid var(--gray200);
  border-radius: 8px;
  gap: 4px;

  span {
    font-size: 13px;
    font-weight: 500;
    color: var(--gray600);
  }

  p {
    padding: 4px 0;
  }
}

.deliveryImageList {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  border-left: 1px solid var(--gray200);
  padding-left: 12px;
  overflow-x: scroll;
}